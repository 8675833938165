import { ItemList } from '../orm'
import { get, size, includes, union, filter } from 'lodash'

class CountryList extends ItemList {
    getEntityKey() {
        return "country"
    }

    getAsOptionsWithPriority(args) {
        const options = this.getAsOptions(args)
        const priority_countries = ["Netherlands"]

        const prioritised_options = union(filter(options, (option) => includes(priority_countries, option.label)),
                                          [{'label': '---', value: null}],
                                          options
                                         )
        return prioritised_options
        
    }
}

export const countryList = new CountryList("country__default")
