/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import Select from 'react-select'
import ReactDatetime from 'react-datetime'
import { Separator } from '../layout/Separator'
import moment from 'moment'
import { split, padStart, map, range, get, isEmpty, size } from 'lodash'
import { jsx, css, Global } from '@emotion/react'
import cross_icon from '../../images/cross.png'
import 'react-datetime/css/react-datetime.css'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import { default_theme as theme } from '../../emotion/theme'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'

const HOUR_OPTIONS = map(range(0, 24), function(hour)  {return {value: `${hour}`, text: padStart(hour, 2, '0')}})
const MINUTE_OPTIONS = map(range(0, 60), function(minute)  {return {value: `${minute}`, text: padStart(minute, 2, '0')}})

export const FormikTimePicker = ({ label, formik_props, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    // Value will must be passed in the form of HH:MM
    const [field, meta] = useField(props)
    const field_name = field.name
    let value = get(formik_props.values, field_name)

    const [minute, setMinute] = useState(null)
    const [hour, setHour] = useState(null)

    useEffect(() => {
        if ( ! value ) {
            value = "12:00"
        }
        const parts = split(value, ":")
        setHour(`${parseInt(parts[0])}`)
        setMinute(`${parseInt(parts[1])}`)
    }, [value])

    const updateValue = function(hour, minute) {
        const newValue = `${padStart(hour, 2, '0')}:${padStart(minute, 2, '0')}`
        formik_props.setFieldValue(field_name, newValue)
    }
    
    const onChangeHour = function(e, {value}) {
        updateValue(value, minute)
    }

    const onChangeMinute = function(e, {value}) {
        updateValue(hour, value)
    }

    console.log(field_name, "hour", hour, "minute", minute)

    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }

          <Global styles={timepicker_global_styles} />
          <SemanticDropdown
            className="timepicker_semantic_dropdown"
            placeholder={'Select'}
            fluid
            selection
            onChange={onChangeHour}
            options={HOUR_OPTIONS}
            value={hour}
            allowAdditions={false}
          />
          <Separator variant="w3" />
          <SemanticDropdown
            className="timepicker_semantic_dropdown"
            placeholder={'Select'}
            fluid
            selection
            onChange={onChangeMinute}
            options={MINUTE_OPTIONS}
            value={minute}
            allowAdditions={false}
          />
          <FormError meta={meta} />
        </div>
    )
}


export const FormikDatePicker = ({ label, placeholder, can_clear, formik_props, ...props }) => {

    const [field, meta] = useField(props)

    const on_change = function(value) {
        const { setFieldValue } = formik_props
        const { name } = field
        formik_props.setFieldValue(name, value)
    }

    if ( can_clear === true && size(field.value) === 0 && field.value !== null ) {
        formik_props.setFieldValue(props.name, null)
    }

    const old_v = field.value
    if ( field.value !== null ) {
        field.value = moment(field.value)
        if ( ! field.value.isValid() ) {
            field.value = moment()
        }
    }

    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }
          <DatePicker value={field.value} can_clear={can_clear} placeholder={placeholder} onChange={on_change} />
          <FormError meta={meta} />
        </div>
    )
}

export const DatePicker = ({ value, placeholder, extra_css, can_clear, onChange, ...props }) => {

    const renderInput = (props, openCalendar, closeCalendar) => {
        const css_styles = props.value ? [form_input, extra_css] : [form_input, disabled_input, extra_css]
        const is_empty = !props.value

        return (
            <div onClick={ openCalendar } css={css_styles}>
              <FontAwesomeIcon css={calendar_icon_style} icon={faCalendarAlt} />
              { props.value || placeholder }

              { can_clear === true && ! is_empty &&
                <div css={clear_icon_wrapper} onClick={() => onChange(null)}>
                  <FontAwesomeIcon css={clear_icon_style} icon={faTimes} />
                </div>
              }
              
            </div>
        )
    }
    const fixed_value = (value && moment(value).toDate()) || null

    return (
        <ReactDatetime inputProps={{ placeholder: placeholder }}
                       closeOnSelect={true}
                       onChange={onChange}
                       value={fixed_value}
                       timeFormat={false}
                       dateFormat={"DD MMMM YYYY"}
                       styling={ form_input }
                       renderInput={ renderInput }
        />
    )
}


const container = css`
display: flex;
position: relative;
`

const form_input = css`
display: flex;
align-items: center;
min-width: 150px;
margin-right: 20px;
position: relative;

/*height: calc(1.5em + 0.75rem + 2px);*/
height: 38px;
padding-top: 0.375rem;
padding-left: 0.75rem;
padding-right: 0.75rem;
padding-bottom: 0.375rem;
font-size: 1rem;
border: 1px solid #ced4da;
border-radius: 0.25rem;
background-color: #fff;

outline: none;

&:hover {
    cursor: pointer;
}

&:focus {
    border: 1px solid ${theme.colors.primary};
    background-color: ${theme.colors.white};
    outline: none;
}
`

const disabled_input = css`
color: #848484;
`


const calendar_icon_style = css`
margin-right: 12px;
color: ${theme.colors.middle_grey};
padding-bottom: 1px;
`

const clear_icon_wrapper = css`
position: absolute;
cursor: pointer;
right: 10px;
`

const clear_icon_style = css`
font-size: 10px;
`

const timepicker_global_styles = css`

.timepicker_semantic_dropdown.ui.dropdown {
min-height: 38px;
max-height: 38px;
padding-top: 10px;
min-width: 75px;
width: 75px;
}

.timepicker_semantic_dropdown.ui.dropdown .dropdown.icon {
top: 8px;
}

.timepicker_semantic_dropdown.ui.dropdown:not(.button)>.default.text {
  color: #000000;
}
`
