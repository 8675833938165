import React, { Component } from 'react'
import { useMediaQuery } from 'react-responsive'
import { breakpoint_mobile_pixels } from '../../emotion/theme'

export const MobileOnly = ({children, ...props}) => {

    const isMobile = useMediaQuery({ maxWidth: breakpoint_mobile_pixels })
    
    const renderUsingBootstrapLogic = () => {
        return (
            <div className="d-lg-none" {...props}>
              {children}
            </div>
        )
    }

    const renderUsingResponsiveLogic = () => {
        if ( ! isMobile ) {
            return null
        }
        return <div {...props}>{children}</div>
    }
    
    return renderUsingResponsiveLogic()
    
}
