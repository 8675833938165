/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MainLayout from './MainLayout'
import { jsx, css } from '@emotion/react'

class Home extends Component {

    render() {
        return (
            <MainLayout>
              Home
            </MainLayout>
        )
    }
}

export default Home
