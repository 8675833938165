import { ItemList } from '../../orm'

class AdminCustomerList extends ItemList {
    getEntityKey() {
        return "admin/customer"
    }

    sendPasswordResetEmail(customer_id) {
        return this.saveObject({id: customer_id, action: 'send_password_reset_email'})
    }

    sendWelcomeEmail(customer_id) {
        return this.saveObject({id: customer_id, action: 'send_welcome_email'})
    }

    createAutoPaymentUrl(customer_id) {
        return this.saveObject({id: customer_id, action: 'create_auto_payment_url'})
    }

    cancelAccount({customer_id, send_email_to_customer}) {
        return this.saveObject({id: customer_id, action: 'cancel_account', action_send_email_to_customer: send_email_to_customer})
    }
}

export const adminCustomerList = new AdminCustomerList("admin_customer__default")
