/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { get } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import loading_icon from '../../images/loading.gif'

export const FormikCheckboxField = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <div css={container}>
          <label css={switch_class}>
            <input css={switch_input_class}
                   checked={field.value}
                   type="checkbox"
                   {...field}
                   {...props}
                   label={label} />
            <span css={slider_class} css={[slider_class, field.value === true ? slider_class__checked__enabled : slider_class__unchecked__enabled ]} />
            <span css={label_style}>{label}</span>
          </label>
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
    )
}

export const FormikSimpleCheckboxField = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <div css={container}>
          <label>
            <input checked={field.value}
                   type="checkbox"
                   {...field}
                   {...props}
                   label={label} />
            <span css={simple_label_style}>{label}</span>
          </label>
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
          
    )
}

export const StandaloneCheckboxField = ({ onChange, label, checked, disabled, is_saving, variant, ...props }) => {

    let classes = {}
    if ( variant === "large" ) {
        classes.standalone_checkbox_field_container_style = standalone_checkbox_field_container_style_large
        classes.switch_class = switch_class_large
        classes.slider_class = slider_class_large
    } else {
        classes.standalone_checkbox_field_container_style = standalone_checkbox_field_container_style
        classes.switch_class = switch_class
        classes.slider_class = slider_class
    }
    
    return (
        <div css={[container_style, classes.standalone_checkbox_field_container_style]}>
          <label css={classes.switch_class}>
            <input css={switch_input_class}
                   checked={checked}
                   type="checkbox"
                   onChange={onChange}
                   {...props}
                   label={label} />
            <span css={[classes.slider_class,
                        checked === true ? (disabled === true ? slider_class__checked__disabled : slider_class__checked__enabled) :
                        (disabled === true ? slider_class__unchecked__disabled : slider_class__unchecked__enabled)
                       ]} />
            <span css={label_style}>{label}</span>
          </label>
          { is_saving && <div css={loading_image_style}><img src={loading_icon} alt="loading"/></div> }
        </div>
          
    )
}


export const FormikFatIconCheckboxField = ({ name, formik_props, ...props }) => {

    const checked = Boolean(get(formik_props.values, name))
    const onToggle = () => {
        formik_props.setFieldValue(name, !checked)
    }
    
    return (
        <div css={fat_checkbox_style} onClick={onToggle}>
          
          { checked &&
            <div css={fat_checkbox_checked_style}>
              <FontAwesomeIcon css={fat_checkbox_icon_style} icon={faCheck} />
            </div>
          }
        </div>
    )
}

export const StandaloneFatIconCheckboxField = ({ checked, onChange, disabled, ...props }) => {

    const onToggle = () => {
        onChange(!checked)
    }
    
    return (
        <div css={fat_checkbox_style} onClick={onToggle}>
          { checked &&
            <div css={fat_checkbox_checked_style}>
              <FontAwesomeIcon css={fat_checkbox_icon_style} icon={faCheck} />
            </div>
          }
        </div>
    )
}

const fat_checkbox_style = css`
cursor: pointer;
height: 24px;
min-width: 24px;
border-radius: 4px;
border: solid 1px #979797;
background-color: var(--10-white);
`

const fat_checkbox_icon_style = css`
height: 20px !important;
width: 20px !important;
`

const fat_checkbox_checked_style = css`
height: 20px !important;
width: 20px !important;
margin: auto;
margin-top: 1px;
`

const container = css`
  width: 100%;
`

const container_style = css`
position: relative;
`

const standalone_checkbox_field_container_style = css`
width: 100%;
height: 20px;
`

const standalone_checkbox_field_container_style_large = css`
width: 100%;
height: 30px;
`

const switch_class = css`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
`

const switch_class_large = css`
  position: relative;
  display: inline-block;
  width: 37px;
  height: 25px;
`


const switch_input_class = css`
  opacity: 0;
  width: 0;
  height: 0;
`

const slider_class = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 15px;

  :before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

`

const slider_class_large = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;

  :before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

`


const slider_class__checked__enabled = css`
  background-color: #2196F3;

  :before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  }
`

const slider_class__checked__disabled = css`
background-color: #8FCAF9;
cursor: default;

  :before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  }
`

const slider_class__unchecked__enabled = css`
  box-shadow: 0 0 1px #2196F3;
`

const slider_class__unchecked__disabled = css`
box-shadow: 0 0 1px #8FCAF9;
cursor: default;
`

const label_style = css`
margin-left: 10px;
position: absolute;
left: 30px;
bottom: 0px;
cursor: pointer;
white-space: nowrap;
`

const simple_label_style = css`
margin-left: 5px;
cursor: pointer;
`

const loading_image_style = css`
opacity: 1;
width: 25px;
position: absolute;
top: 0px;
left: 0px;
`
