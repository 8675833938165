/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { connect } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import CardInfoText from './layout/CardInfoText'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { withRouter } from 'react-router-dom'
import { FormikInputField } from './form/InputField'
import { Separator } from './layout/Separator'
import { get } from 'lodash'
import { jsx, css } from '@emotion/react'
import { customerList } from '../actions/customer'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import show_dark from '../images/show_dark.png'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirm_password: Yup.string().required("Required")
})

class NewPasswordForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show_password: false,
        }
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(customerList.ensureCustomerLoaded())
    }

    toggleShowPassword = () => {
        const { show_password } = this.state
        this.setState({
            show_password: !show_password,
        })
    }
    
    render() {
        const { onSubmit, error, customer } = this.props
        const { show_password } = this.state
        const has_set_password_at_least_once = get(customer, ["user", "has_set_password_at_least_once"])

        return (
            <Formik
              initialValues={{}}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form css={ form_layout } >
                        <Translation>{ (t) => (
                            <>
                              <FormikGeneralFormErrors />
                              
                              <div css={ error_container }>
                                { error && <p>{ error }</p>}
                              </div>

                              <div className="form-group" css={ field_container }>
                                <CardInfoText>
                                  { has_set_password_at_least_once &&
                                    <Trans>You have asked to reset your password. Please enter a new password below</Trans>
                                  }
                                  { !has_set_password_at_least_once &&
                                    <Trans>Before you can continue, you must first create a password for your account</Trans>
                                  }
                                </CardInfoText>
                                <Separator variant="h10" />
                              </div>
                              
                              <div className="form-group" css={ field_container }>
                                <div css={ row }>
                                  <label css={ label }>Password</label>
                                  <div>
                                    <img src={ show_dark } alt="" css={ eye_con } onClick={ this.toggleShowPassword } />
                                  </div>
                                </div>
                                <div>
                                  <FormikInputField
                                    name="password"
                                    type="password"
                                    placeholder={t("Password")}
                                  />
                                </div>
                              </div>
                              <div className="form-group" css={ field_container }>
                                <label css={ label }>Confirm Password</label>
                                <div>
                                  <FormikInputField
                                    name="confirm_password"
                                    type="password"
                                    placeholder={t("Password")}
                                  />
                                </div>
                              </div>
                              <div css={ footer }>
                                <div>
                                  <Button type="submit">Save</Button>
                                </div>
                              </div>
                            </>
                        )}</Translation>
                      </Form>
                  )
              }}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSubmit } = props
    const customer = customerList.getCustomer()
    return {
        onSubmit,
        customer
    }
}
export default withRouter(connect(mapStateToProps)(NewPasswordForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};

`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: flex-end;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex;
`

const eye_con = css`
height: 24px;
padding-left: 5px;
margin-bottom: 8px;
&:hover{
cursor: pointer;
}
`
