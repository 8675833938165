/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { SubmissionError } from '../orm/error'
import { Trans } from 'react-i18next'
import LoginForm from './LoginForm'
import { Error } from './layout/Error'
import { login, isLoggedIn, loggedInUser, sendForgotPasswordEmail } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import { Formik, Form, Field } from 'formik'
import ownyournumbers_logo from '../images/logo.png'
import { handleSubmitResult } from '../actions/form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required")
})

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = { tokenError: null }
    }

    handleSubmit = (values, formik_funcs) => {
        const { dispatch } = this.props

        const on_ok = () => {
        }

        if (!values || !values.email || !values.password) {
            formik_funcs.setFieldError("non_field_errors", "Incomplete credentials provided")
        } else {
            return dispatch(login(values.email, values.password)).then((res) => handleSubmitResult({res, formik_funcs, on_ok, enable_error_popup: false}))
        }
    }

    onRequestToken = (formik_props) => {
        const { dispatch, history } = this.props
        const loginFormValues = formik_props.values
        if (!loginFormValues || !loginFormValues.email) {
            this.setState({tokenError: 'No username entered'})
        } else {
            dispatch(sendForgotPasswordEmail(loginFormValues.email))
            this.setState({tokenError: null})
            history.push({
                pathname: '/token',
                state: { email: loginFormValues.email }
            })
        }
    }

    renderAutoLoginFailure() {
        const { auto_login_error_message } = this.props
        return (
            <Error>
              <div>
                <Trans>Failed to automatically login</Trans>
              </div>
              <div>
                <Trans>Either the token has expired or is invalid</Trans>
              </div>
              <div>
                <Trans>Please login normally to continue</Trans>
              </div>
            </Error>
        )
    }

    render() {
        const { tokenError } = this.state
        const { auto_login_error_message } = this.props
        const that = this
        return (
            <div css={ main }>
              <div css={ container }>
                <div css={ header }>
                  <p css={ label }><Trans>Welcome to</Trans> <img src={ ownyournumbers_logo } alt="" css={ image } /></p>
                </div>
                { auto_login_error_message && this.renderAutoLoginFailure() }
                <Formik
                  initialValues={{}}
                  onSubmit={that.handleSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >

                  {formik_props => {
                      const { values } = formik_props

                      return (
                          <LoginForm onRequestToken = {() => this.onRequestToken(formik_props) }
                                     formik_props={formik_props}
                                     tokenError={ tokenError }
                          />
                      )
                  }}
                </Formik>
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { auto_login_error_message } = props
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        auto_login_error_message
    }
}
export default withRouter(connect(mapStateToProps)(Login))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
width: 100%;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
`

const header = css`
text-align: center;
padding-top: 50px;
font-size: 24pt;
width: 100%;
`

const label = css`
color: #484a49;
font-weight: 600;
`

const image = css`
max-height: 100px;
`
