import { get } from 'lodash'
import {
    CLEAR_AUTH_TOKEN,
    USER_LOGGED_IN,
    ANNOUNCE_LOGGING_IN_USER,
    ANNOUNCE_USER_NOT_LOGGED_IN,
    ANNOUNCE_LANGUAGE_CHANGED,
    FORCE_LANGUAGE_CODE_PRELOGIN,
    LANGUAGE_UPDATED,
    authToken
} from '../actions/auth'

const initialState = {
    token: authToken(),
    user: null,
    isLoading: false,
    isLoggedIn: false,
    API_BASE: get(window, 'LOCAL_SETTINGS.API_BASE', '/')
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ANNOUNCE_LOGGING_IN_USER:
            return Object.assign({}, state, {isLoading: true, })
        case ANNOUNCE_USER_NOT_LOGGED_IN:
            return Object.assign({}, state, {token: null, user: null, isLoading: false, hasCheckedLogin: true})
        case ANNOUNCE_LANGUAGE_CHANGED:
            const new_user = Object.assign({}, get(state, "user", {}), {language_code: action.language_code})
            return Object.assign({}, state, {language_code: action.language_code, user: new_user})
            case USER_LOGGED_IN:
                return Object.assign({}, state, {
                    user: action.user,
                    isLoading: false,
                    isLoggedIn: true,
                    hasCheckedLogin: true,
                    token: action.token,

                })
        case CLEAR_AUTH_TOKEN:
            return Object.assign({}, state, {user: null, token: null, isLoading: false, hasCheckedLogin: false, isLoggedIn: false})
        case FORCE_LANGUAGE_CODE_PRELOGIN:
            return Object.assign({}, state, { language_code_prelogin: action.language_code })
        case LANGUAGE_UPDATED:
            return Object.assign({}, state, { last_language_update: action.language_code })
        default:
            return state
    }
}
