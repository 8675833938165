import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { breakpoint_mobile_pixels } from '../../emotion/theme'

export const DesktopOnly = ({children, ...props}) => {

    const isDesktop = useMediaQuery({ minWidth: breakpoint_mobile_pixels+1 })
    
    const renderUsingBootstrapLogic = () => {
        return (
            <div className="d-none d-lg-block" {...props}>
              {children}
            </div>
        )
    }
    
    const renderUsingResponsiveLogic = () => {
        if ( ! isDesktop ) {
            return null
        }
        return <div {...props}>{children}</div>
    }

    return renderUsingResponsiveLogic()
    
}
