/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import i18n from '../i18n'
import { get, includes } from 'lodash'
import { isProd } from '../actions/ui'
import TokenSent from './TokenSent'
import WrappingBusyMask from './WrappingBusyMask'
import Login from './Login'
import { auto_login, setPostAuthenticatedAction,
         getPostAuthenticatedAction, clearPostAuthenticatedAction,
         updateUserActiveLanguage } from '../actions/auth'
import AdminRouter from '../admin/components/AdminRouter'
import Home from './Home'
import { Capture } from './Capture'
import Profile from './Profile'
import ProfileEdit from './ProfileEdit'
import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isLoggedIn, loggedInUser } from '../actions/auth'
import { globalSettingsList } from '../actions/settings'

class AuthRouter extends Component {

    constructor(props) {
        super(props)
        this.state = {auto_logging_in: false,
                      auto_login_error_message: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
        if ( ! this.check_for_language() ) {
            this.check_for_auto_login()
            this.checkForPostAuthenticatedAction()
        }
    }

    componentDidUpdate(prevProps) {
        const { user, dispatch, is_logged_in, history, is_superuser, post_authenticated_action } = this.props

        if ( ! this.check_for_language() ) {
            this.check_for_auto_login()
            this.checkForPostAuthenticatedAction()
        }
        
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                if ( post_authenticated_action.action === "go" ) {
                    history.push(post_authenticated_action.value)
                } else {
                    history.push('/')
                }
            }
        } else if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        } else if ( is_superuser && window.location.pathname === "/" ) {
            history.push('/admin/dashboard')
        }
    }
    
    checkForPostAuthenticatedAction() {
        const { history } = this.props
        const params = new window.URLSearchParams(window.location.search);
        const action = params.has("action") && params.get("action")
        const action_value = params.has("value") && params.get("value")
        if ( action ) {
            setPostAuthenticatedAction(action, action_value)
            params.delete("action")
            params.delete("value")
            history.push(window.location.pathname + "?" + params.toString())
        }
    }

    check_for_language() {
        const { history, dispatch } = this.props 
        const params = new window.URLSearchParams(window.location.search);
        const language_code = params.has('language') && params.get('language')

        if ( ! language_code ) {
            return false
        }
        params.delete("language")
        dispatch(updateUserActiveLanguage(language_code))
        history.push(window.location.pathname + "?" + params.toString())
        return true
    }
    
    check_for_auto_login() {
        const { history, dispatch, is_logged_in } = this.props
        const { auto_logging_in } = this.state
        const that = this
        const params = new window.URLSearchParams(window.location.search);
        const user_id = params.has("u") && params.get("u")
        const otp = params.has("o") && params.get("o")
            
        const on_done = (res) => {
            that.setState({auto_logging_in: false})
            if ( res.errors ) {
                that.setState({auto_login_error_message: res.errors})
            }
            params.delete("u")
            params.delete("o")
            history.push(window.location.pathname + "?" + params.toString())
        }
        
        if ( user_id && otp && !auto_logging_in ) {
            this.setState({auto_logging_in: true})
            dispatch(auto_login(user_id, otp)).then(on_done)
        }
    }

    handlePostAuthenticatedAction({action, value}) {
        const { history } = this.props
        clearPostAuthenticatedAction()
        if ( action === "go" ) {
            history.push(value)
        }
    }

    goLogin = () => {
        const { post_authenticated_action } = this.props
        const { auto_logging_in, auto_login_error_message } = this.state
        if ( ! post_authenticated_action.action && window.location.pathname !== "/" ) {
            setPostAuthenticatedAction("go", window.location.pathname + window.location.search)
        }
        return <Login auto_login_error_message={auto_login_error_message} />
    }

    render() {
        const { is_logged_in, is_prod, user, post_authenticated_action } = this.props
        const { auto_logging_in } = this.state

        if ( ! is_logged_in ) {
            return (
                <WrappingBusyMask is_loading={auto_logging_in}>
                  <div>
                    <Switch>
                      <Route path="/token" exact={ true } component={ TokenSent } />
                      <Route path="/" render={this.goLogin} />
                    </Switch>
                  </div>
                </WrappingBusyMask>
            )
        }

        if ( post_authenticated_action.action ) {
            this.handlePostAuthenticatedAction(post_authenticated_action)
        }
        return (
            <div>
              <Switch>
                <Route path="/admin" exact={ false } component={ AdminRouter } />
                <Route path="/profile/edit" exact={ true } component={ ProfileEdit } />
                <Route path="/profile" exact={ true } component={ Profile } />
                <Route path="/home" component={ Home } />
                <Route path="/capture" component={ Capture } />
                <Route path="/" exact={true} component={ Home } />
                <Route path="/" component={ Home } />
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let is_superuser = false
    const user = loggedInUser()
    if (is_logged_in) {
        is_superuser = user && user.is_superuser
    }
    
    return {
        user,
        is_logged_in,
        is_superuser,
        is_prod: isProd(),
        post_authenticated_action: getPostAuthenticatedAction()
    }  
}
  
export default withRouter(connect(mapStateToProps)(AuthRouter))
