/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import ownyournumbers_logo from '../../images/logo.png'
import ownyournumbers_logo_staging from '../../images/logo.png'

import { isProd } from '../../actions/ui'

export const Logo = ({style}) => {

    const is_prod = isProd()
    return (
        <img src={ is_prod ? ownyournumbers_logo : ownyournumbers_logo_staging }
             alt="ownyournumbers"
             css={ style || default_style } />
    )

}

const default_style = css`
height: 26px;
`
