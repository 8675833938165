/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import ReactCountryFlag from "react-country-flag"

export const LanguageFlag = ({ language_code, label, ...props }) => {

    let flag_name = language_code
    if ( language_code === 'en' ) {
        flag_name = 'gb'
    }
    
    return (

        <ReactCountryFlag
            className="emojiFlag"
            countryCode={flag_name}
            style={{
                fontSize: '1.5em',
                lineHeight: '1.5em',
            }}
            aria-label={label}
        />
    )

}
