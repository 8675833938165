import { ItemList } from '../orm'
import { loggedInUser, storeActiveLanguage } from './auth'
import { get, size } from 'lodash'

class CustomerList extends ItemList {
    getEntityKey() {
        return "customer"
    }

    ensureCustomerLoaded() {
        return (dispatch, getState) => {
            const customer_id = get(loggedInUser(), "customer")
            if ( customer_id ) {
                return dispatch(this.ensureObjectLoaded(customer_id)).then( (res) => {
                    if ( res && size(res) == 1 ) {
                        const customer = res[0]
                        dispatch(storeActiveLanguage(get(customer, ["user", "language_code"])))
                    }
                })
            }
            return null
        }
    }

    getCustomer() {
        const customer_id = get(loggedInUser(), "customer")
        return this.getObject(customer_id)
    }

    invalidateCustomer() {
        const customer_id = get(loggedInUser(), "customer")
        return this.invalidateObject(customer_id)
    }

    createResellerAutoLoginUrl({customer}) {
        return this.saveObject({action: 'create_reseller_login_link', id: customer.id})
    }

    hasAgreedToTermsAndConditions() {
        const customer = this.getCustomer()
        return get(customer, "agreed_to_terms_and_conditions_at", null) !== null
    }
    
}

export const customerList = new CustomerList("customer__default")
export const customerListForReseller = new CustomerList("customer_for_reseller__default")
