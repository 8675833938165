/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'

import TokenLoginForm from './TokenLoginForm'

import { login } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import ownyournumbers_logo from '../images/logo.png'

class TokenSent extends Component {
    
    handleSubmit = (values) => {
        const { dispatch, email } = this.props
        return dispatch(login(email, values.password))
    }

    onCancelToken = () => {
        const { dispatch, history } = this.props
        history.push('/')
    }
    
    render() {
        const { email } = this.props
        return (
            <div css={ main }>
              <div css={ container }>
                <div css={ header }>
                  <img src={ ownyournumbers_logo } alt="" css={ image } />
                  { email &&
                    <p><Trans>A login token has been sent to your email account</Trans>: <span css={ mail }>{ email }</span>
                      <br/><br/>
                      <Trans>Please check your email inbox and enter the token into the password box</Trans>.
                    </p>
                  }
                </div>
                <TokenLoginForm
                    onSubmit={ this.handleSubmit }
                    onCancelToken={ this.onCancelToken }
                />
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        email: props && props.location && props.location.state && props.location.state.email
    }
}
export default withRouter(connect(mapStateToProps)(TokenSent))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
`

const header = css`
padding-top: 30px;
width: 80%;
text-align: center;
p {
padding-top: 20px;
}
`

const mail = css`
color: ${theme.colors.primary};
`

const image = css`
width: 100%;
max-height: 100px;
`
