/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MainLayout from './MainLayout'
import { jsx, css } from '@emotion/react'

export const Capture = () => {

    return (
        <MainLayout>
          Capture numbers
        </MainLayout>
    )
}
