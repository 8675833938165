/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { map, get } from 'lodash'
import { FormikDropdownField } from './Dropdown'
import ToolTip from '../ToolTip'

const YES_NO_OPTIONS = [ {value: null, label: 'Any'},
                         {value: true, label: 'Yes'},
                         {value: false, label: 'No'} ]


export const Tristate = ({name, label_prefix, label_overrides, placeholder, tooltip, formik_props}) => {
    
    const options = map(Object.assign([], YES_NO_OPTIONS), function(option) {

        const label = get(label_overrides, option.value, `${label_prefix ? label_prefix + " " : ""}${option.label}`)
        return { value: option.value, label: label}
    })
    
    return (
        <div css={container_style}>
          <FormikDropdownField name={name}
                               options={options}
                               placeholder={placeholder}
                               formik_props={formik_props} />
          <div css={tooltip_style}> 
            { tooltip && <ToolTip>{tooltip}</ToolTip> }
          </div>
        </div>
    )
}

const container_style = css`
position: relative;
`

const tooltip_style = css`
position: absolute;
right: 0px;
top: 0px;
`
