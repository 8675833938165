/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { withRouter } from 'react-router-dom'
import { InlineIcon } from './layout/InlineIcon'
import { MobileOnly } from './layout/MobileOnly'
import { DesktopOnly } from './layout/DesktopOnly'

class BreadLinks extends Component {

    render() {
        const { history } = this.props
        
        // default is to hide
        const enable_breadlinks = this.props.enable_breadlinks === true

        if (! enable_breadlinks ) {
            return (
                <>
                <MobileOnly>
                  <Separator variant="h20" />
                </MobileOnly>
                <DesktopOnly>
                  <Separator variant="h50" />
                </DesktopOnly>
                </>
            )
        }
        
        return (
            <div css={container}>
              <BlueLinkButton onClick={history.goBack}>
                <InlineIcon icon_name="chevron-left" variant="blue" />
                Back
              </BlueLinkButton>
            </div>
        )
    }
}

export default withRouter(BreadLinks)

const container = css`
padding-left: 30px;
height: 30px;
margin-top: 12px;
margin-bottom: 8px;
`
