/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { connect } from 'react-redux'
import moment from 'moment'
import Form from 'react-bootstrap/Form'
import { Separator } from '../layout/Separator'

export class FormLabelValue extends Component {

    render() {

        const { spacing } = this.props
        const label = this.props.children[0]
        const value = this.props.children.slice(1)
        
        return (
            <Form.Group css={form_group_style}>
              <Form.Label>{label}</Form.Label>
              {value}
              { spacing && <Separator variant={spacing} /> }
            </Form.Group>
        )
        
    }

}

const form_group_style = css`
margin-bottom: 20px;
`
