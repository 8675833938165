/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { get } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {showSuccess, showError} from '../../actions/Error'
import AdminMainLayout from './AdminMainLayout'
import AdminTableHeader from './AdminTableHeader'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikInputField } from '../../components/form/InputField'
import { adminCustomerList } from '../actions/admin_customer'
import { Container, Button, Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
})

export const AdminCustomer = ({}) => {

    const { customer_id } = useParams()
    
    const dispatch = useDispatch()
    const history = useHistory()
    const customer = useSelector(() => customer_id && adminCustomerList.getObject(customer_id))
    const initialValues = customer || {}

    useEffect(() => {
        if ( customer_id ) {
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        }
    }, [customer_id])

    const onSave = (values, formik_funcs) => {
        const that = this
        const on_ok = function(json) {
            dispatch(adminCustomerList.invalidateList())
            dispatch(adminCustomerList.fetchListIfNeeded())
            showSuccess("Saved", "Customer saved")
            history.push(`/admin/customers/`)
        }
        if ( customer_id ) {
            values.id = customer_id
            return dispatch(adminCustomerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCustomerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }    
    
    return (

        <AdminMainLayout active_key="customer"
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                       {name: 'customer', label: get(customer, ["user", "display_name"], "New customer"), url: `/admin/customer/${customer_id}`}]}>
          <AdminTableHeader title={get(customer, ["user", "display_name"], "New customer")} />
          <Container fluid>

            <Row>
              <Col md={6}>
                
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSave}
                  enableReinitialize={true}
                >
                  {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />

                            <FormikInputField name="user.email"
                                              placeholder="Email"
                            />

                            <FormikInputField name="user.first_name"
                                              placeholder="Name"
                            />

                            <FormikInputField name="company.name"
                                              placeholder="Company"
                            />

                            <Separator variant="h20" />
                            
                            <BlueButton type="submit">
                              Save
                            </BlueButton>
                            
                          </Form>
                      )
                  }}
                </Formik>
              </Col>
            </Row>
          </Container>
        </AdminMainLayout>
    )

}
