import {
    SET_ACTIVE_MODAL,
    SET_MODAL_PROPS
} from '../actions/ui'

const initialState = {
    active_modal: '',
    modal_params: {},
    modal_props: {}
}

export default function ui(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_MODAL:
            return Object.assign({}, state, {active_modal: action.active_modal, modal_params: action.modal_params })
        case SET_MODAL_PROPS:
            const state_clone = Object.assign({}, state, {modal_props: Object.assign({}, state.modal_props)})
            state_clone.modal_props[action.modal_name] = Object.assign({}, action.modal_props)
            return state_clone
        default:
            return state
    }
}
 
