import { ItemList } from '../orm'
import { startsWith, get, size, map, union, join } from 'lodash'
import PhoneNumber from 'awesome-phonenumber'

class CityList extends ItemList {
    getEntityKey() {
        return "city"
    }

    filterOnCountry(country_id) {
        return this.updateListFilter({country:country_id})
    }

    getAsOptions({empty_option_label, show_prefix_in_label}) {
        let items = this.getVisibleObjects()
        items = map(items, function(item) {
            let label = item.name
            if ( show_prefix_in_label && size(item.prefixes) > 0 ) {
                label = join(map(item.prefixes, (x) => convertCityPrefixToNationalFormat(x)), ",") + " - " + label
            }
            return {label: label, value: item.id}
        })
        if ( empty_option_label ) {
            items = union([{label: empty_option_label, value: null}], items)
        }
        return items
    }

    
}

export function convertCityPrefixToNationalFormat(number) {
    if ( size(number) == 0 ) {
        return number
    }
    if ( startsWith(number, "00") ) {
        number = number.slice(2)
    }
    const pn = new PhoneNumber("+" + number)
    return "0" + pn.getNumber('national')
}

export const cityList = new CityList("city__default")
