import { onGeneralServerError, onSubmissionError } from './Error'
import { map, keys } from 'lodash'

const dummy_formik_funcs = {setFieldError: null, setSubmitting: null, setFieldTouched: null}

export const handleSubmitResult = ({res, formik_funcs, on_ok, enable_error_popup}) => {

    if ( enable_error_popup === undefined ) {
        enable_error_popup = true
    }

    const is_form = formik_funcs && true
    formik_funcs = formik_funcs || dummy_formik_funcs
    const {setFieldError, setSubmitting, setFieldTouched} = formik_funcs

    if ( res.errors ) {
        if ( res.errors._error && enable_error_popup ) {
            onGeneralServerError(res)
        } else {
            if ( is_form && enable_error_popup ) {
                onSubmissionError(res)
            } else {
                if ( enable_error_popup ) {
                    onGeneralServerError(res)
                }
            }
        }

        if ( setFieldError ) {
            map(keys(res.errors), function(field_name) {
                if ( field_name === "_error" ) {
                    setFieldTouched("non_field_errors", true, false)
                    setFieldError("non_field_errors", res.errors[field_name])
                } else {
                    const msg = res.errors[field_name]
                    if ( msg.non_field_errors ) {
                        setFieldTouched("non_field_errors", true, false)
                        setFieldError("non_field_errors", msg.non_field_errors[0])
                    } else {
                        setFieldTouched(field_name, true, false)
                        setFieldError(field_name, msg)
                    }
                }
            })
        }
    } else {
        on_ok(res)
    }

    if (setSubmitting ) {
        setSubmitting(false)
    }
}
