/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { jsx, css } from '@emotion/react'
import { get, head, has, map } from 'lodash'
import { BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { adminDashboardList } from '../actions/admin_dashboard'
import { adminUserList } from '../actions/admin_user'
import AdminMainLayout from './AdminMainLayout'
import DashboardItem from '../../components/DashboardItem'
import BusyMask from '../../components/BusyMask'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import AdminTableFilter from './AdminTableFilter'
import { default_theme as theme } from '../../emotion/theme'
import content_dark from '../../images/content_dark.png'
import active_dark from '../../images/active_dark.png'
import inactive_dark from '../../images/inactive_dark.png'
import send_dark from '../../images/send_dark.png'
import sms_dark from '../../images/sms_outline_dark.png'
import notification_dark from '../../images/notification_outline_dark.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { globalSettingsList } from '../../actions/settings'

const USER_FILTER = {'page': '1'}

class AdminDashboard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminDashboardList.invalidateList())
        dispatch(adminDashboardList.fetchListIfNeeded())
        dispatch(adminUserList.invalidateList())
        dispatch(adminUserList.updateListFilter(
            Object.assign(USER_FILTER, {active: true})
        ))
        dispatch(adminUserList.fetchListIfNeeded())
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminUserList.fetchListIfNeeded())
    }

    formatXAxis(tickItem) {
        return moment(tickItem).format('DD MMM')
    }

    formatXAxisHours(tickItem) {
        return moment(tickItem).format('DD MMM HH:00')
    }

    renderChart(title, data, colour, {xAxisFormatter}) {
        const that = this
        if ( ! data ) {
            return null
        }
        colour = colour || "#63aed1"
        return (
            <div css={chart_container}>
              <h1>{title}</h1>
              <ResponsiveContainer width="100%" height="80%">
                <BarChart
                  width={1000}
                  height={1000}
                  data={data}
                  margin={{
                      top: 5, right: 30, left: 20, bottom: 60
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tickFormatter={xAxisFormatter || that.formatXAxis} angle={-45} textAnchor="end" />
                  <YAxis />
                  <Bar type="monotone" dataKey="count" stroke={colour} activeDot={{ r: 8 }} />
                </BarChart>
              </ResponsiveContainer>
            </div>
        )
    }

    renderIframeWidget(index, widget) {
        return (
            <Row key={index}>
              <Col>
                <a href={widget.url}>{widget.title}</a> 
              </Col>
            </Row>
        )
    }

    render() {
        const {
            data,
            is_busy,
            is_ready,
            users,
            user_headers,
            user_filter,
            is_user_loading,
            dashboard_widgets
        } = this.props

        const that = this
        const error_colour = "#ff0000"
        const info_colour = "#63aed1"
        
        return (
            <AdminMainLayout title='Dashboard'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'dashboard', label: 'Dashboard', url: '/admin/dashboard'}]} >
              { is_busy && <BusyMask /> }
              <Container>

                <Row>
                  <Col>
                    { this.renderChart("Voxbone unprocessed incoming faxes", get(data, ["fax_stats", "voxbone_failed"]), error_colour, {}) }
                  </Col>
                  <Col>
                    { this.renderChart("Unwiped Files", get(data, ["fax_stats", "file_infos_unwiped"]), error_colour, {}) }
                  </Col>
                </Row>

                <Row>
                  <Col>
                    { this.renderChart("Failed incoming faxes", get(data, ["fax_stats", "failed_incoming_faxes"]), error_colour, {}) }
                  </Col>
                  <Col>
                    { this.renderChart("Failed outgoing faxes", get(data, ["fax_stats", "failed_outgoing_faxes"]), error_colour, {}) }
                  </Col>
                </Row>

                <Row>
                  <Col>
                    { this.renderChart("Successful incoming faxes", get(data, ["fax_stats", "successful_incoming_faxes"]), info_colour, {}) }
                  </Col>
                  <Col>
                    { this.renderChart("Successful outgoing faxes", get(data, ["fax_stats", "successful_outgoing_faxes"]), info_colour, {}) }
                  </Col>
                </Row>

                <Row>
                  <Col>
                    { this.renderChart("Sync log", get(data, ["sync_stats", "data_sync_logs_per_hour"]), info_colour,
                                       {xAxisFormatter:that.formatXAxisHours}) }
                  </Col>
                </Row>

                <Row>
                  <Col>
                    { this.renderChart("Phone number redirect", get(data, ["sync_stats", "phone_number_redirects"]), info_colour,
                                      {xAxisFormatter:that.formatXAxisHours}) }
                  </Col>
                </Row>

                <Row>
                  <Col>
                    { this.renderChart("Customer migrations", get(data, ["sync_stats", "customer_migrations"]), info_colour,
                                      {xAxisFormatter:that.formatXAxisHours}) }
                  </Col>
                </Row>

                
                <Row>
                  <Col>
                    <DashboardItem
                        onClickUrl="/admin/customers"
                        background_colour={ theme.colors.logo_1 }
                        icon={ content_dark }
                        stat={ data.customers }
                        is_ready={ is_ready }
                        text={ "Customers" }
                    />
                  </Col>
                </Row>

                { map(dashboard_widgets, (dashboard_widget, index) => {
                    if ( dashboard_widget.type === "iframe" ) {
                        return that.renderIframeWidget(index, dashboard_widget)
                    } else {
                        console.error(`Unknown widget type ${dashboard_widget.type}`)
                        return null
                    }
                })}
                
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state) {
    const data = head(adminDashboardList.getVisibleObjects()) || {}
    const users = adminUserList.getVisibleObjects() || []
    return {
        data,
        users,
        is_ready: adminDashboardList.isReady() && adminUserList.isReady(),
        is_busy: adminDashboardList.isLoading() || adminUserList.isLoading(),
        is_user_loading: adminUserList.isLoading(),
        user_filter: adminUserList.getFilter(),
        user_headers: {
            name: { name: 'Name', column_size: 3 },
            description: { name: 'Description', column_size: 2 },
        },
        dashboard_widgets: globalSettingsList.getSetting('dashboard_widgets')
    }
}
export default connect(mapStateToProps)(AdminDashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const label = css`
padding-top: 10px;
color: white;
font-weight: 600;
font-size: 18px;
`

const chart_container = css`
width: 100%;
height: 400px;
`
