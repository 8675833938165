/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { map, size } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'

export const ButtonBar = ({children, style_extra, align_right, item_style_extra, ...props }) => {

    const item_style = align_right ? item_style_align_right : item_style_align_left
    
    return (
        <div css={[style,
                   style_extra,
                   align_right ? align_right_style : null]}>
          
          { !children.length && <div css={item_style}>{children}</div> }
          { children.length > 1 && map(children, (child, index) => <div key={index} css={[item_style, item_style_extra || null]}>{child}</div>) }
          
        </div>
    )

}

const style = css`
display: flex;
align-items: center;
justify-content: flex-start;
`

const item_style_align_left = css`
margin-right: 20px;
`

const item_style_align_right = css`
margin-left: 20px;
`

const align_right_style = css`
width: 100%;
display: flex;
justify-content: flex-end;
`
