import { ItemList } from '../orm'

class UserList extends ItemList {
    getEntityKey() {
        return "user"
    }

    requestChangeEmail({user_id, new_email}) {
        // Step 1 : triggers an otp
        return this.saveObject({id: user_id, email: new_email})
    }

    requestChangeEmailWithOtp({user_id, new_email, otp}) {
        // Step 2 : supply the otp, this actually does the change
        return this.saveObject({id: user_id, email: new_email, otp: otp})
    }

    requestChangePassword({user_id, new_password}) {
        // Step 1 : triggers an otp
        return this.saveObject({id: user_id, password: new_password})
    }

    requestChangePasswordWithOtp({user_id, new_password, otp}) {
        // Step 2 : supply the otp, this actually does the change
        return this.saveObject({id: user_id, password: new_password, otp: otp})
    }

    cancelAccount({user_id}) {
        return this.saveObject({id: user_id, action: 'cancel_account'})
    }
    
}

export const userList = new UserList("user__default")
