/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Separator } from './layout/Separator'
import { withRouter } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import { globalSettingsList } from '../actions/settings'
import { LanguageFlagSelector } from './LanguageFlagSelector'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'

import { default_theme as theme } from '../emotion/theme'

class LoginForm extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(globalSettingsList.fetchListIfNeeded())
    }

    render() {
        const { onRequestToken, error, tokenError, customer_can_create_accounts, formik_props } = this.props
        const that = this

        // Get the parameters from the URL
        const query_from_URL_parameters = new window.URLSearchParams(this.props.location.search)
        const is_redirect_from_legacy = query_from_URL_parameters.get('redirect_to_new') === 'true'

        console.log("formik_props", formik_props)
        
        return (
            <Translation>{ (t) => (
                <Form css={[form_layout]}>

                  <FormikGeneralFormErrors />
                  
                  <div css={ error_container }>
                    { error && <p><Trans>{ error }</Trans></p>}
                    { tokenError && <p><Trans>{ tokenError }</Trans></p>}
                    { is_redirect_from_legacy && this.renderLegacyRedirect() }
                  </div>


                  <div className="form-group" css={ field_container }>
                    <label css={ label }><Trans>Email address</Trans></label>
                    <div>
                      <FormikInputField
                        autoFocus
                        autoComplete="off"
                        name="email"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }><Trans>Password or token</Trans></label>
                    <div>
                      <FormikInputField
                        autoFocus
                        autoComplete="off"
                        name="password"
                        type="password"
                      />
                    </div>
                  </div>
                  <div css={ footer }>
                    <div>
                      <Button variant="secondary" onClick={onRequestToken }>
                        <Trans>Forgot Password</Trans>
                      </Button>
                      { customer_can_create_accounts &&
                        <>
                          <Separator variant="w20" />
                          <Link to={"/signup"}>
                            <Trans>Sign up</Trans>
                          </Link>
                        </>
                      }
                    </div>
                    <div>
                      <Button type="submit">
                        <Trans>Login</Trans>
                      </Button>
                    </div>
                  </div>

                  <div css={language_selector_style}>
                    <LanguageFlagSelector />
                  </div>
                </Form>
            )}
            </Translation>
        )
    }

    renderLegacyRedirect() {
        return (
            <div>
                <Trans>You have been migrated.</Trans>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { onRequestToken, tokenError, formik_props } = props
    const customer_can_create_accounts = globalSettingsList.getSetting("customer_can_create_accounts") === true
    return {
        onRequestToken,
        tokenError,
        customer_can_create_accounts,
        formik_props
    }
}
export default withRouter(connect(mapStateToProps)(LoginForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};

`

const footer = css`
display: flex;
flex-direction: row;
text-align: left;
justify-content: space-between;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const language_selector_style = css`
position: fixed;
top: 50px;
right: 20px;
`
