import i18n from '../i18n'
import { includes, map } from 'lodash'

export const LANGUAGES = [ {language_code:'nl', language_name:'Nederlands'},
                           {language_code:'en', language_name:'English'} ]

export const DEFAULT_LANGUAGE_CODE = "nl"

export const getBestLanguageCode = () => {
    let code = i18n.language
    if ( !includes(map(LANGUAGES, (x) => x.language_code), code)) {
        code = DEFAULT_LANGUAGE_CODE
    }
    return code
}

