import { get, includes } from 'lodash'

export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL"
export const CONFIRMATION_MODAL_KEY = "CONFIRMATION_MODAL"
export const SET_MODAL_PROPS = "SET_MODAL_PROPS"

export function confirmModal({text, onConfirmed, can_cancel, confirm_text, cancel_text}) {
    return {
        type: SET_ACTIVE_MODAL,
        active_modal: CONFIRMATION_MODAL_KEY,
        modal_params: {text, onConfirmed, can_cancel, confirm_text, cancel_text}
    }
}

export function isProd() {
    return !includes(window.location.host, "localhost") && !includes(window.location.host, "staging")
}

export function getActiveModal(state) {
    return get(state, ['ui', 'active_modal'], null)
}

export function getModalParams(state) {
    return get(state, ['ui', 'modal_params'], {})
}

export function setActiveModal(modal_key, params) {
    return {
        type: SET_ACTIVE_MODAL,
        active_modal: modal_key,
        modal_params: params
    }
}

export function clearActiveModal() {
    return setActiveModal("")
}

export function setModalProps(modal_name, modal_props) {
    return {
        type: SET_MODAL_PROPS,
        modal_name: modal_name,
        modal_props: modal_props,
    }
}

export function getModalProps(state, modal_name) {
    return get(state, ['ui', 'modal_props', modal_name], {})
}

export function constructPostSignupUrl(url, query_params) {
    const base_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`
    const url_obj = new window.URL(`${base_url}/signup`)
    url_obj.search = new window.URLSearchParams(Object.assign({}, {action: "go", value: url}, query_params))
    return url_obj.toString()
}

export const historyPushRetainQueryParams = (history, new_url) => {
    const params = new window.URLSearchParams(window.location.search);
    history.push(new_url + "?" + params.toString())
}
