import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import AdminDashboard from './AdminDashboard'
import AdminCustomers from './AdminCustomers'
import AdminQuestionnaires from './AdminQuestionnaires'
import { AdminCustomer } from './AdminCustomer'
import { isLoggedIn, loggedInUser } from '../../actions/auth'

class AuthRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }
    
    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/admin/dashboard" exact={ true } component={ AdminDashboard } />
                <Route path="/admin/customer" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customer/:customer_id" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customers" exact={ true } component={ AdminCustomers } />
                <Route path="/admin/questionnaires" exact={ true } component={ AdminQuestionnaires } />
                
                <Route path="/admin" component={ AdminDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
