/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { jsx, css } from '@emotion/react'
import { WhiteButton } from '../../components/layout/WhiteButton'
import Card from '../../components/layout/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Separator } from '../../components/layout/Separator'

export const AdminTableFilterPopup = ({fixed_fields, popup_fields}) => {

    const [showPopup, setShowPopup] = useState(false)

    const renderPopup = () => {
        return (
            <div css={popup_style}>
              <Card variant="white_popup">
                { popup_fields }
                <div css={close_button_style}>
                  <WhiteButton auto_disable={false} onClick={() => setShowPopup(false)}>
                    Close
                  </WhiteButton>
                </div>
              </Card>
            </div>
        )
    }
    
    return (

        <div css={container_style}>
          <WhiteButton auto_disable={false} onClick={() => setShowPopup(!showPopup)}>
            <FontAwesomeIcon icon={faFilter} css={search_icon_style} />
            Filter
          </WhiteButton>
          <Separator height="w10" />
          {fixed_fields}

          { showPopup && renderPopup() }
          
        </div>
        
    )
    
}

const container_style = css`
position: relative;
display: flex;
z-index:99;
`

const search_icon_style = css`
    margin-right: 10px;
`


const popup_style = css`
position: absolute;
top: 30px;
right: 0px;
`

const close_button_style = css`
display: flex;
justify-content: flex-end;
margin-top: 5px;
`
