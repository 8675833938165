/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { userList } from '../actions/user'
import { customerList } from '../actions/customer'
import { FormikInputField } from './form/InputField'
import { FormikDropdownField } from './form/Dropdown'
import BusyMask from './BusyMask'
import { countryList } from '../actions/country'
import { cityList } from '../actions/city'
import { Row, Col, Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'
import FieldInfoText from './layout/FieldInfoText'
import { FormLabelValue } from './form/FormLabelValue'
import { BlueButton } from './layout/BlueButton'
import { WhiteButton } from './layout/WhiteButton'
import { ButtonBar } from './layout/ButtonBar'
import { Separator } from './layout/Separator'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { Trans, Translation } from 'react-i18next'
import { handleSubmitResult } from '../actions/form'

const validationSchema = Yup.object().shape({
    // 'contact_email': Yup.string().email('Email must be valid').required("Email is required").nullable()
})

class ProfileForm extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    onAddCity = (new_city_name, city_field_name, country_field_name, formik_props) => {
        const { dispatch } = this.props

        const on_ok = (json) => {
            dispatch(cityList.invalidateList())
            dispatch(cityList.fetchListIfNeeded())
            formik_props.setFieldValue(city_field_name, json.id)
        }

        const country = get(formik_props.values, country_field_name)
        if ( country ) {
            const values = {name: new_city_name, country: country}
            dispatch(cityList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_props, on_ok}))
        }
    }
    
    render() {
        const { customer, initial_values, onSave, onCancel, error, renderAdditionalFields,
                country_options, city_options } = this.props

        const that = this
        const left_width = 3
        const right_width = 6
        
        return (

            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Translation>{ (t) => (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              <Col md={left_width} css={section_name_style}>
                                <Trans>Name</Trans>
                              </Col>
                              <Col md={right_width}>
                                <FormLabelValue>
                                  <Trans>First name</Trans>
                                  <FormikInputField
                                    name="user.first_name"
                                    type="text"
                                    placeholder={t("First name")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Surname</Trans>
                                  <FormikInputField
                                    name="user.last_name"
                                    type="text"
                                    placeholder={t("Surname")}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                            <Separator variant="h50"/>
                            <Row>
                              <Col md={left_width} css={section_name_style}>
                                <Trans>Contact details</Trans>
                              </Col>
                              <Col md={right_width}>
                                <FormLabelValue>
                                  <Trans>Contact email</Trans>
                                  <FormikInputField
                                    name="contact_email"
                                    type="text"
                                    placeholder={t("Contact email")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Contact phone</Trans>
                                  <FormikInputField
                                    name="contact_phone_number"
                                    type="text"
                                    placeholder={t("Contact phone")}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                            <Separator variant="h50"/>
                            <Row>
                              <Col md={left_width} css={section_name_style}>
                                <Trans>Company information</Trans>
                              </Col>
                              <Col md={right_width}>
                                <FormLabelValue>
                                  <Trans>Company name</Trans>
                                  <FormikInputField
                                    name="company.name"
                                    type="text"
                                    placeholder={t("Company name")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Address 1</Trans>
                                  <FormikInputField
                                    name="company.billing_address.street1"
                                    type="text"
                                    placeholder={t("Address 1")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Address 2</Trans>
                                  <FormikInputField
                                    name="company.billing_address.street2"
                                    type="text"
                                    placeholder={t("Address 2")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Postal code</Trans>
                                  <FormikInputField
                                    name="company.billing_address.postal_code"
                                    type="text"
                                    placeholder={t("Postal code")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Country</Trans>
                                  <FormikDropdownField name="company.billing_address.country"
                                                       formik_props={formik_props}
                                                       options={country_options}
                                                       on_change={that.onCountryChanged}
                                                       empty_selection_label={t("Country")}
                                                       placeholder={t("Country")} />
                                </FormLabelValue>
                                { get(formik_props, ["values", "company", "billing_address", "country"]) &&
                                  <FormLabelValue>
                                    <Trans>City</Trans>
                                    <FormikDropdownField name="company.billing_address.city"
                                                         formik_props={formik_props}
                                                         options={city_options}
                                                         empty_selection_label={t("City")}
                                                         onAddItem={(new_value) => that.onAddCity(new_value, "company.billing_address.city", "company.billing_address.country", formik_props)}
                                                         placeholder={t("City")} />
                                  </FormLabelValue>
                                }
                                <FormLabelValue>
                                  <Trans>Chamber of Commerce number</Trans>
                                  <FormikInputField
                                    name="company.chamber_of_commerce"
                                    type="text"
                                    placeholder={t("Chamber of commerce number")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>VAT number</Trans>
                                  <div>
                                    <FormikInputField
                                      name="company.vat_number"
                                      type="text"
                                      placeholder={t("VAT number")}
                                    />
                                  </div>
                                </FormLabelValue>
                              </Col>
                            </Row>
                            
                            <Separator variant="h50"/>

                            { renderAdditionalFields && renderAdditionalFields({formik_props, left_width, right_width, section_name_style}) }

                            <Row>
                              <Col md={left_width} css={section_name_style}>
                              </Col>
                              <Col md={right_width}>
                                <ButtonBar>
                                  <BlueButton type="submit"><Trans>Save</Trans></BlueButton>
                                  <WhiteButton onClick={onCancel}><Trans>Cancel</Trans></WhiteButton>
                                </ButtonBar>
                              </Col>
                            </Row>
                          </Form>
                      )}</Translation>
                  )}
              }
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSave, onCancel, customer, renderAdditionalFields } = props
    const country_options = countryList.getAsOptionsWithPriority()
    const city_options = cityList.getAsOptions({show_prefix_in_label:true})

    const initial_values = Object.assign({contact_email: ''}, customer)
    if ( ! customer ) {
        initial_values.user = {}
        initial_values.company = {}
    }
    
    return {
        onSave,
        onCancel,
        country_options,
        city_options,
        initial_values: initial_values,
        renderAdditionalFields
    }
}
export default withRouter(connect(mapStateToProps)(ProfileForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 90%;
display: flex;
flex-direction: column;
`

const error_container = css`
p {
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 90%;
padding-top:20px;
padding-bottom:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`
