/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { get, map, head, filter } from 'lodash'
import {showSuccess, showError} from '../actions/Error'
import i18n from '../i18n'
import { Button } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { updateUserActiveLanguage } from '../actions/auth'
import { LANGUAGES } from '../actions/language'
import { DropdownStandalone } from './form/Dropdown'
import { LanguageFlag } from './LanguageFlag'

export const getActiveLanguageCode = () => {
    return i18n.language
}

export const LanguageFlagSelector = ({show_success, onChange}) => {

    const dispatch = useDispatch()
    const active_language_code = getActiveLanguageCode()

    const [selectedLanguageCode, setSelectedLanguageCode] = useState(active_language_code)

    const last_language_update = useSelector( (store) => get(store, ["auth", "last_language_update"]) )
    if ( last_language_update && last_language_update !== selectedLanguageCode ) {
        setSelectedLanguageCode(last_language_update)
    }
    
    const onSelectLanguage = (language_code) => {
        setSelectedLanguageCode(language_code)
        const on_done = () => {
            if ( show_success ) {
                showSuccess(<Trans>Saved</Trans>)
            }
        }
        dispatch(updateUserActiveLanguage(language_code, on_done))
        if ( onChange ) {
            onChange(language_code)
        }
    }

    const renderLanguage = (language) => {
        return (
            <div key={language.language_code}
                 onClick={() => onSelectLanguage(language.language_code)}
                 css={[language_option_style,
                       selectedLanguageCode === language.language_code ? language_option_active_style: language_option_inactive_style]}>
              <LanguageFlag language_code={language.language_code} />
            </div>
        )
    }
    
    return (
        <div css={container_style}>
          {map(LANGUAGES, (language) => renderLanguage(language))}
        </div>
    )
}

const container_style = css`
display: flex;
`

const language_option_style = css`
margin-right: 10px;
cursor: pointer;

&:hover {
opacity: 0.8;
}
`

const language_option_active_style = css`
opacity: 1.0;
`

const language_option_inactive_style = css`
opacity: 0.2;
`
